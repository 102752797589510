<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="less">
  #app {
    min-height: 100vh;
  }
</style>
