<template>
  <div>
    <slot></slot>
  </div>
</template>

<script></script>

<style lang="less" scoped>
  div {
    color: var(--text-color-primary);
  }
</style>
